import React from "react";

function FigmaDesigns() {
    return (
        <div>
            <iframe title="yeet" style={{border: "1px solid rgba(0, 0, 0, 0.1)", width:"100%", height:"700px"}} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FkFHv6pahM1N6rYEToTjoc5%2FJared-Rudnicki's-team-library%3Fnode-id%3D315%253A3" allowfullscreen></iframe>
        </div>
    )
}

export default FigmaDesigns;